import React, {useEffect,useState} from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Korg00 from '../assets/korg00.webp';
import { useGlitch } from 'react-powerglitch';
import Marketing from '../assets/Supply.webp';
import Supply from '../assets/Supply2.webp';
import CreateWallet from '../assets/CreateWallet.svg';
import EthToWallet from '../assets/EthToWallet.svg';
import CreateUniWallet from '../assets/CreateUniWallet.svg';
import EthForKorg from '../assets/EthForKorg.svg';
import Iframe from 'react-iframe';
import KorgCarousel from './KorgCarousel';
import ToTop from './ToTop';
import SplineKorg from './SplineKorgDos';
import AskedGrok from '../assets/WeAskedGrok.webp';


const Home = () => {

   



    const glitcho = useGlitch(
 
        );

        useEffect(() => {
            AOS.init({duration: 1000});
          }, [])
    
    
     
      
    return ( 
        <div >
            <div className='MasterContainer'> 
            <ToTop/>
            <div className='MainContainer'>
            <div  className='section korghome' data-aos="fade-right" id='home'>
            <div className='sectionsContent'>
            <div className='HomeRight'>
                    
                 </div>
                <div className='HomeLeft'>
                    <h1 className='toGradient' style={{fontSize:'3em'}}>Hello and welcome to my financial funhouse</h1>
                    <ol style={{fontWeight:'400'}}>
                        <li className='listText'>I'm KORG, your rock-solid, wise-cracking, quirky crypto guide.</li>
                        <li className='listText'>Imagine diving into the world of decentralized finance with a buddy who’s part brainiac, part intergalactic rock warrior.</li>
                        <li className='listText' style={{marginBottom:'2em'}}> Let’s make finance fun again one stepping stone at a time.</li>
                      
                        <li className='listButton'><a href='https://t.me/KORGPUBLIC' target='blank_'>
                <button  style={{width:'200px', height:'40px', justifyContent:'center', marginRight:'2em'}} className='BuyNowAlt' >JOIN TELEGRAM</button></a>
                <a href='https://app.uniswap.org/#/swap?outputCurrency=0x52bB63C1e9195E6AB90EDeBaA4f3123DFB1F7BaF&use=V2' target='blank_'>
                <button  style={{width:'200px', height:'40px', justifyContent:'center'}} className='BuyNow' >BUY ON UNISWAP</button></a>
                </li>
                    </ol>
                </div>
                
                
            </div>
            </div>
            <div  className='section' data-aos="fade-left" style={{height:'auto'}}>
                <div className='SectionTwo2' style={{height:'auto'}}>
                <h1 className='toGradient' style={{fontSize:'3em', marginBottom:'2em', marginTop:'1em'}}>WE ASKED GROK...</h1>
                <div className='TokenomicsContent'>
                <img className='WeAsked' src={AskedGrok} alt="AskedGrok" />
   



</div>
</div>

</div>
            <div  className='section' data-aos="fade-right" style={{justifyContent:'flex-start', height:'auto'}}>
                <div className='SectionTwo'>

                    <h1 className='toGradient' style={{fontSize:'3em', marginBottom:'2em', marginTop:'1em'}}>TOKENOMICS</h1>
                
                    <div className='TokenomicsContent'>
                     <div className='TokeText'>
                     
                        <div className='TokenomicsImg'><dir className='DashedRotated'></dir><img className='tokeimg' src={Marketing} alt="" /></div>
                        <h2 className='toGradient'>2% MARKETING</h2>
                        <p className='text02'>2% of buy and 2% of sell will go towards the marketing of the token</p>
                    </div>
                     <div className='TokeText'>
                        
                        <div className='TokenomicsImg'><dir className='DashedRotated2'></dir><img className='tokeimg' src={Supply} alt="" />
                        
                        </div>
                        <h2 className='toGradient'>TOTAL SUPPLY</h2>
                        <p className='text02'>There will be a total supply of 9.6 billion tokens.</p>
                    </div>   
                    
                    
                    </div>
                </div>
            </div>


            <div  className='section' data-aos="fade-left"  style={{height:'auto'}}>
                <div className='SectionTwo'>
                    
                    <h1 className='toGradient' style={{fontSize:'3em', marginBottom:'2em', marginTop:'1em'}}>HOW TO BUY</h1>
                        <div className='BadgesContainer'>
                            <div className='Badges' data-aos="fade-up" data-aos-delay="100">
                                <img className='BadgesImg' src={CreateWallet} alt="" />
                                <h2 style={{fontSize:'1.2em'}} className='toGradient'>CREATE WALLET</h2>
                                <p>Create a MetaMask or Trust Wallet using either a desktop computer or an iOS/Android mobile device. That will allow you to buy, sell, send, and receive $KORG</p>
                            </div>
                            <div className='Badges' data-aos="fade-up" data-aos-delay="300">
                                <img className='BadgesImg' src={EthToWallet} alt="" />
                                <h2 style={{fontSize:'1.2em'}} className='toGradient'>SEND ETH TO YOUR WALLET</h2>
                                <p>You can buy Ethereum (ETH) directly on MetaMask / Trust wallet or transfer it to your Wallet from exchanges like Coinbase, Binance, etc.)</p>
                            </div>
                            <div className='Badges' data-aos="fade-up" data-aos-delay="500">
                                <img className='BadgesImg' src={CreateUniWallet} alt="" />
                                <h2 style={{fontSize:'1.2em'}} className='toGradient'>CONNECT YOUR WALLET TO UNISWAP</h2>
                                <p>Click ‘Connect to a wallet’ and selecting KORG by the Contract Address</p>
                            </div>
                            <div className='Badges' data-aos="fade-up" data-aos-delay="700">
                                <img className='BadgesImg' src={EthForKorg} alt="" />
                                <h2 style={{fontSize:'1.2em'}} className='toGradient'>SWAP ETHEREUM (ETH) FOR KORG</h2>
                                <p>You can start swapping as soon as you have ETH available! Press ‘Select a token’ and enter the token address $KORG</p>
                            </div>
                        </div>
                    
                </div>


            </div>
            <div style={{marginBottom:'3em', marginTop:'2em'}}><KorgCarousel  /> </div>
               

            <div  className='section' data-aos="fade-right">

            <div className='SectionTwo2'>

                <h1 className='toGradient' style={{fontSize:'3em', marginBottom:'2em', marginTop:'1em'}}>ROADMAP</h1>
                <div className='SurfDesktopBack'>

                </div>
                <div className='SurfMobileBack'></div>
                
                <div className='TokenomicsContent'>
               
               
          


            </div>
            </div>

            </div>
            <div  className='section' data-aos="fade-left" style={{height:'auto'}}>
                <div className='SectionTwo'>
                    
                    <h1 className='toGradient' style={{fontSize:'3em', marginBottom:'1em', marginTop:'1em'}}>LIVE CHART</h1>
                        <div className='ChartContainer' >
                        <Iframe className='chartStyle' width="100%" height="600" src="https://dexscreener.com/ethereum/0x52bB63C1e9195E6AB90EDeBaA4f3123DFB1F7BaF?embed=1&amp;theme=dark"/>
                        </div>
                        <h1 className='toGradient' style={{fontSize:'3em', marginBottom:'1em', marginTop:'1em'}}>SWAP TOKENS</h1>
                        <div className='ChartContainer' >
                        <Iframe className='chartStyle' width="100%" height="600" src="https://app.uniswap.org/swap?theme=dark&use=v2?outputCurrency=0x52bB63C1e9195E6AB90EDeBaA4f3123DFB1F7BaF?embed=1&amp;theme=dark"/>
                        </div>
                        <div><SplineKorg/></div>
                </div>


            </div>
            
            </div>
            </div>
        </div>
        
    ); 
};

export default Home;
