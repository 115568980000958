import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { HashLink } from 'react-router-hash-link';
import KorgLogo from '../assets/logo_korg.png';

const Topbar = () => {

    const [visible, setVisible] = useState(false) 
  
    const toggleVisible = () => { 
      const scrolled = document.documentElement.scrollTop; 
      if (scrolled > 300){ 
        setVisible(false) 
      }  
      else if (scrolled <= 300){ 
        setVisible(true) 
      } 
    }; 
    
    const scrollToTop = () =>{ 
      window.scrollTo({ 
        top: 0,  
        behavior: 'smooth'
        /* you can also use 'auto' behaviour 
           in place of 'smooth' */
      }); 
    }; 
    
    window.addEventListener('scroll', toggleVisible); 

    useEffect(() => {
        AOS.init({duration: 1000});
      }, [])

    
     
      
    return ( 
    <div className='topbarContainer' data-aos="fade-down">
        <div className='topbar'>
            
           <div className='KorgLogo' ><img className='KorgLogoImg' src={KorgLogo} alt="KORGLogo" /><p className='textGradient'>KORG</p></div>
           <div className='navigationItems'>
            
            <p className='NavigationButtons' onClick={scrollToTop}>Home</p>
            <a style={{textDecoration:'none', color:'white'}} href="https://linktr.ee/KorgAI" target='blank_'>
            <p className='NavigationButtons'>LinkTree</p></a>
            
           
          
             
               <a href='https://app.uniswap.org/#/swap?outputCurrency=0x52bB63C1e9195E6AB90EDeBaA4f3123DFB1F7BaF&use=V2' target='blank_'>
                <button   className='BuyNow' >BUY NOW</button></a>
                
             
             
            
           </div>
           </div>
        </div>
    ); 
};

export default Topbar;
