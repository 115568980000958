import React, { useState, useEffect } from 'react';
import { useRef } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Spline from '@splinetool/react-spline';

export default function App() {

 
 

  const objectToAnimate = useRef();

  function onLoad(spline) {
    const obj = spline.findObjectByName('korg');
    // save the object in a ref for later use
    objectToAnimate.current = obj;
  }

  function triggerAnimation() {
    const splineObject = objectToAnimate.current;
    if(!splineObject)
        return;
   
    splineObject.emitEvent('mouseDown');
    splineObject.emitEvent('mouseHover');
  }
  
  const[isOpen ,setIsOpen] = useState(false);
  const toggle = () => setIsOpen (!isOpen);

  useEffect(() => {
    AOS.init({duration: 1000});
  }, [])


  return (
    <div className='SplineForKorg' >
      <Spline
        scene="https://prod.spline.design/yLiYhfBQEmY9SCom/scene.splinecode"
        onLoad={onLoad}
     style={{display: isOpen ?"none" : "flex"}} />
      <button className='BuyNow' style={{marginLeft:'30%', maxWidth:'100px', display: isOpen ?"none" : "flex"}} type="button"  onClick={triggerAnimation}>
      Korg Approves
      </button>
      <button className='BuyNowAlt' style={{position:'relative',marginLeft:'30%', maxWidth:'100px', marginTop:'1em', top: isOpen ? "-190px" : ""}} type="button"  onClick={toggle}>
      <p style={{display:isOpen ? "none" : "flex"}}>Hide Korg</p>
      <p style={{display:isOpen ? "flex" : "none"}}>Show Korg</p>
      </button>
    </div>
  );
}