import React from 'react';
import {DefaultPLayer as Video} from 'react-html5video';
import videoBackground from '../assets/BackgroundIO_2.webm';




const videoUno = () => {
    
    return (
        <div>
            <div className='videoBackground'>
           <video className='videoInside' muted  autoPlay='autoplay' loop='loop' playsinline='playsinline' poster='' >
          
            <source muted src={videoBackground} type='video/webm'  />
            
           </video>
           <div className='VideoOverlay'></div>
           </div>
        </div>
    );
};

export default videoUno;