import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { HashLink } from 'react-router-hash-link';
import KorgLogo from '../assets/logo_korg.png';
import MenuIcon from '@mui/icons-material/Menu';

const Topbar = () => {
  const [visible, setVisible] = useState(false) 
  
  const toggleVisible = () => { 
    const scrolled = document.documentElement.scrollTop; 
    if (scrolled > 300){ 
      setVisible(false) 
    }  
    else if (scrolled <= 300){ 
      setVisible(true) 
    } 
  }; 
  
  const scrollToTop = () =>{ 
    window.scrollTo({ 
      top: 0,  
      behavior: 'smooth'
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    }); 
  }; 
  
  window.addEventListener('scroll', toggleVisible); 
  

    

    useEffect(() => {
        AOS.init({duration: 1000});
      }, [])

    
      const[isOpen ,setIsOpen] = useState(false);
      const toggle = () => setIsOpen (!isOpen);
      
    return ( 
    <div className='topbarContainerMobile' data-aos="fade-down" style={{width: isOpen ? "70%" : "",}}>
        <div style={{height: isOpen ? "98vh" : "50px", 
                    flexDirection: isOpen ? "column" : "row", 
                    justifyContent: isOpen ? "flex-start" : "flex-start",
                    background: isOpen ?"linear-gradient(180deg, #001606 0%, rgba(30, 0, 0, 0.95) 100%)" : "",
                    }} className='topbar_mobile' >
            
           <div className='KorgLogo' style={{width: isOpen ? "100%" : "", 
                                            height:'60px',
                                            background: isOpen ? "#000000c4" : "",
                                            marginLeft:isOpen ? "-1px" : ""}}><img className='KorgLogoImg' src={KorgLogo} alt="KORGLogo"  /><p className='textGradient'>KORG</p></div>
           <div className='navigationMobile' 
           style={{ flexDirection: isOpen ? "column" : "row", 
                     
                    fontSize: isOpen ? "2em" : "0em"}}>
            <div></div>
            <div></div>
            <div></div>
            <p className='NavigationButtons' style={{display: isOpen ? "" : "none"}} onClick={scrollToTop}>Home</p>
            <a style={{textDecoration:'none', color:'white'}} href="https://linktr.ee/KorgAI" target='blank_'>
            <p className='NavigationButtons'>LinkTree</p></a>
            
           
          
             
               
                
                
             
             
            
           </div>
           <button style={{marginRight: isOpen ? "2em" : "2em", height:'80%', height:'35px', transform: isOpen ? "scale(1.5)" : "scale(1)"}}  className='BuyNowMobile' onClick={toggle}><MenuIcon/></button>
           </div>
        </div>
    ); 
};

export default Topbar;