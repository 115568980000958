import React from 'react';
import { HashRouter, Route, Routes} from 'react-router-dom';

import logo from './logo.svg';
import './korg.css';
import Topbar from './components/Topbar'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Home from './components/Home'
import BackgroundIO from './components/Video';
import MobileTopbar from './components/Topbar_Mobile';


function App() {
  return (
    
    <div className="App">
      
      
        <Home/>
        
      
        <Topbar/>
        <MobileTopbar/>
        <BackgroundIO/>
    
    </div>
  );
}

export default App;
