import React from 'react';
import Marquee from "react-fast-marquee";
import Carousel1 from '../assets/Carousel/Carousel1.webp';
import Carousel2 from '../assets/Carousel/Carousel2.webp';
import Carousel3 from '../assets/Carousel/Carousel3.webp';
import Carousel4 from '../assets/Carousel/Carousel4.webp';
import Carousel5 from '../assets/Carousel/Carousel5.webp';
import Carousel6 from '../assets/Carousel/Carousel6.webp';
import Carousel7 from '../assets/Carousel/Carousel7.webp';
import Carousel8 from '../assets/Carousel/Carousel8.webp';
import Carousel9 from '../assets/Carousel/Carousel9.webp';
import Carousel10 from '../assets/Carousel/Carousel10.webp';
import Carousel11 from '../assets/Carousel/Carousel11.webp';
import Carousel12 from '../assets/Carousel/Carousel12.webp';

const Marqueez = () => {
    
    return (
        <div className='MarqueeMaster 'style={{ maxWidth:'3600px'}} >
            <div className='MarqueeOverlay'></div>
    <Marquee direction='right' pauseOnHover={true} autoFill={true}   >
    <div style={{height:'100%'}}><img style={{height:'100%'}} src={Carousel5} alt="Carousel5" /></div>
    <div style={{height:'100%'}}><img style={{height:'100%'}} src={Carousel2} alt="Carousel2" /></div>
    <div style={{height:'100%'}}><img style={{height:'100%'}} src={Carousel3} alt="Carousel3" /></div>
    <div style={{height:'100%'}}><img style={{height:'100%'}} src={Carousel4} alt="Carousel4" /></div>
    <div style={{height:'100%'}}><img style={{height:'100%'}} src={Carousel1} alt="Carousel1" /></div>
    <div style={{height:'100%'}}><img style={{height:'100%'}} src={Carousel6} alt="Carousel6" /></div>
    <div style={{height:'100%'}}><img style={{height:'100%'}} src={Carousel7} alt="Carousel7" /></div>
    <div style={{height:'100%'}}><img style={{height:'100%'}} src={Carousel8} alt="Carousel8" /></div>
    <div style={{height:'100%'}}><img style={{height:'100%'}} src={Carousel9} alt="Carousel9" /></div>
    <div style={{height:'100%'}}><img style={{height:'100%'}} src={Carousel10} alt="Carousel10" /></div>
    <div style={{height:'100%'}}><img style={{height:'100%'}} src={Carousel11} alt="Carousel11" /></div>
    <div style={{height:'100%'}}><img style={{height:'100%'}} src={Carousel12} alt="Carousel12" /></div>
  

    </Marquee>           
        </div>
    );
};

export default Marqueez;